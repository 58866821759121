import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel'
import ReactPlayer from 'react-player/lazy'
import { sports } from './SportsData'

function SportsDay() {
    const [items, setItems] = useState([])

    useEffect(() => {
      const temp = []
      for(let sport of sports){
        const media = []
        const mediaPath = sport.mediaPath
      
        for(var i=1;i<=sport.images;i++){
            media.push(<img src={mediaPath + "img" + i + ".jpg"} width="600" height="500" />)
        }
  
        for(var i=1;i<=sport.videos;i++){
            media.push(<ReactPlayer 
              className='react-player'
              url={mediaPath + "video" + i + ".mp4"}
              controls 
              width='100%'
              height='100%'
          />)
        }

        temp.push(
          {
            ...sport,
            media: media
          }
        )
      }

      setItems(temp)

    }, [])   

  return (
    <div className="events-sports-page">
      <section id="title"><h1>Sports Day</h1></section>
      {items.length > 0 && items.map((item) => {
        return ( 
        <section id="activity-section">
          <AliceCarousel 
            autoHeight   
            items={item.media} 
            onSlideChange = {() => {
                var videoList = document.getElementsByTagName("video");
                for (var i = 0; i < videoList.length; i++) {
                    videoList[i].pause();
                }
            }}
            />
          <Container className="pt-50 data-desc">
              {item.header.map((header) => <h3>{header}</h3>)}
              {item.data.map((data) => <p>{data}</p>)}
          </Container>
          <hr/>
      </section>)
      })}
     
    </div>
  );
}

export default SportsDay
import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel'
import ReactPlayer from 'react-player/lazy'

function Mela(props) {

    const [items,setItems] = useState([])
    const mediaPath = "/assets/events/mela/"

    useEffect(() => {
         
        for(var i=1;i<=37;i++){
            let img = mediaPath + "img" + i + ".jpg"
            setItems((prevImages) => [...prevImages, <img src={img} width="600" height="500" />])
        }
    
        for(var i=1;i<=0;i++){
            let video = mediaPath + "video" + i + ".mp4"
            setItems((prevVideos) => [...prevVideos, <ReactPlayer 
                className='react-player'
                url={video}
                controls 
                width='100%'
                height='100%'
            />])
        }

    }, [])


  return (
    <div className="events-mela-page">
      <section id="title"><h1>Mela</h1></section>
      <section id="activity-section">
        <AliceCarousel 
          autoHeight   
          items={items} 
          onSlideChange = {() => {
              var videoList = document.getElementsByTagName("video");
              for (var i = 0; i < videoList.length; i++) {
                  videoList[i].pause();
              }
          }}
          />
        <Container className="pt-50 data-desc">
            <h3>Date : 22nd Jan, 2023 </h3>
            <h3>Venue : Vidyanidhi School Grounds, Juhu</h3>
            <p>This year Khel Katha organised its first biggest event, though not the last - “THE MELA”. In the event, we saw a footfall north of 450 people including parents and children of all ages. Khel Katha has been organising Online Vocational Training Classes for the last 2 years for the Government School students who do not have the opportunity and means to learn these skills. We realised that just teaching them doesn’t make them understand the value of learning these things. </p>
            <p>So in January 22, 2023 we organised a Mela where we sold the items made by the Each One Teach One students. We had taught them Mehendi, Candles, Soap, Bag decoration, Block printing, Best out of waste coasters & Zentangle Art. We put stalls for each of these items. </p>
            <p>To have fun with our friends we put up seven game stalls. These games were Feed the Clown, Smash the Cans, Chopstick game, Toss the ring etc. We even had selfie corner, art corner, and a stage for performances. The art corner was great success. It kept the little children busy and they loved showing their creativity. The teachers couldn’t manage the flow of kids.</p>
            <p> The stage was a platform for budding dancers, singers and musicians. We had some beautiful acts. Talents showcased by our friends took us by surprise. For tiny tots we had put up a ball pool. They were busy jumping in it, while the mothers were busy shopping our beautiful creations. </p>
            <p>We erected an art wall where we had asked the EOTO children to send their drawings. We got some very beautiful paintings. Seeing their talent we decided to give gifts and certificates to the best 5 children. </p>
            <p>We organised some delicious food stalls like pizza, popsicles, chole bhature, and many more. To help with the mela we asked our friends to volunteer. We received an over whelming response from friends to help us organise the mela. Feels good that our organisation is growing with more children wanting to volunteer and help us make lives better for not so fortunate children in the world. </p>
            <p>The mela was a grand success. We got very positive feed back from the visitors. People loved the product these children had made. Few products like soaps and coasters were all sold out. We even got featured in the newspaper. All this gives us the confidence that our efforts are in the right directions and pushes us to work even harder. Next year we will be coming up with a better and a bigger mela.</p>
        </Container>
      </section>
    </div>
  );
}

export default Mela
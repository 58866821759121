export const core = [
    {
        imgSrc : "/assets/team/dhruv.jpg",
        name: "Dhruv Chauhan",
        designation: "Co-head Sports",
        description:"A naturally versatile sports person and martial artist, Dhruv is most passionate about football, hiking and skiing. He is also a wildlife enthusiast and photographer. As a committed changemaker, he has used his robotics skills to help manual scavengers and to clean water bodies, and even summitted Mt.Kilimanjaro to fund Khel Katha initiatives!"
    },
    {
        imgSrc : "/assets/team/soham.jpg",
        name: "Soham Chauhan",
        designation: "Co-head Vocational training",
        description:"Soham is a trained Capoerista, passionate footballer and an avid mountaineer. As a music lover, the violin is his favorite companion. Always driven towards helping society, his initiatives have taken him from building robot prototypes for BMC workers to organising rural clean up drives, and even to the roof of Africa !"
    },
    {
        imgSrc : "/assets/team/shaurya2.jpeg",
        name: "Shaurya Shah",
        designation: "Co-head Sports",
        description:"Shaurya, a dynamic force in sports outreach, draws from his experience as the sports captain of his School. From I-League football to martial arts, including a black belt and international kudo honors, his leadership fosters athleticism and excellence in our crew."
    },
    {
        imgSrc : "/assets/team/adya.jpg",
        name: "Adya Thanawala",
        designation: "Co-Head Vocational Training",
        description:"Adya is an excellent artist, a bronze world champion in Karate with over 10 years of experience and a black belt, a phenomenal aerialist and a professional and passionate baker. She uses her experience across these diverse fields to be a holistic leader and an active team member, impacting and nurturing vocational and sports verticals of Khelkatha."
    }
]

export const team = [
    {
        imgSrc : "/assets/team/radha.jpg",
        name: "Radha Doshi",
        description:"Radha is a talented footballer, winning the ISSO National Under-19 Girls Football Tournament twice. She's dedicated a decade to perfecting Odissi dance and reached Level 3 in Aerial silk and hoop. In her free time, she enjoys snorkeling ,reading and knitting."
    },
    {
        imgSrc : "/assets/team/advait.jpg",
        name: "Advait Berde",
        description:"Advait is a black belt in karate and a dedicated football player and watcher. In his free time, he indulges in reading novels and watching movies."
    },
    {
        imgSrc : "/assets/team/srihari.jpg",
        name: "Srihari Subramanian",
        description:"Srihari is a badminton player, mathematician with a passion for programming and Lego building. He enjoys travelling, international cuisines and rock music."
    }
]

export const volunteers = [
    {
        name: "Tia Katharani"
    }, 
    {
        name: "Awantika Chaudhry"
    },
    {
        name: "Tyisha Golechha"
    }, 
    {
        name: "Vritti Tulsiyan"
    }, 
    {
        name: "Maanasvi Rajwani"
    }, 
    {
        name: "Maanasvi Rajwani"
    }, 
    {
        name: "Reya Kejriwal"
    }, 
    {
        name: "Krisha Savla"
    }
]
import React from 'react'
import '../Goal.scss'
import { Container, Card } from 'react-bootstrap';

function Story() {
  return (
   <div className="story-page">
    <section id="title"><h1>Our Story</h1></section>
    <section>
      <Container>
        <div className="text-center">
          <img src="/assets/misc/story.jpeg" style={{width:"100%"}}/>
        </div>
        <br/>
        <Card className="yellow">
          <p>From a young age, we have been passionate about sports, engaging in various activities as we grew up. However, we recognised that the high costs associated with sports, from equipment to training, are making sports less accessible, potentially sidelining future Olympic athletes at an early age. At Khel Katha, we aim to remove these barriers for underprivileged children by providing sports training and the necessary equipment, ensuring every child can pursue sports regardless of their financial situation.</p>
          <p>Additionally, we have initiated online vocational training to cater to children with different interests and talents beyond sports and academics. Recognizing that some children excel in creative endeavors, we offer courses in activities like mehendi, painting, and zentangle, among others. These courses are taught by professional instructors via Zoom, allowing these children to explore their talents and potentially earn a livelihood. Through these efforts, we hope to make a meaningful difference in the lives of less fortunate children by offering them various paths to financial independence.</p>
          <p>Teaching both sports and vocational skills has been immensely fulfilling for us, as it brings joy and meaning to our lives, seeing the transformative effects on the children we teach. These sessions are the highlight of our week, and through Khel Katha, we are committed to cultivating these young talents and promoting a broad appreciation for both sports and creative pursuits.</p>
          <p>Teaching sports and Vocational training has also been a rewarding experience for us, bringing joy and fulfillment as we see the positive impact on the children's lives. Our sessions have become a highlight of our week, and through Khel Katha, we are eager to nurture these young talents and foster a widespread appreciation for sports and Vocational Activity.</p>
        </Card>
      </Container>
    </section>
   </div>
  );
}

export default Story;

export const sports = [
    {
        "mediaPath": "/assets/events/sports-day/sports-2/",
        "images" : 16,
        "videos" : 0,
        "header": ["Date : 16th Dec, 2023", "Venue : Bal Vikas Manoranjan Maidan"],
        "data" : ["Khel Katha hosted the sports day along with Each One Teach One foundation. 22 Municipal Schools from Maharashtra took part in it.", "We started the sports day with a prayer followed by warmup through Zumba.", "We had around 100 students taking part in different athletic competitions like 100mt, 200mt races, relays and long jumps. Balwadi children along with their parents also participated in these races. These children were between the age group of 3-5 years old. They were very enthusiastic to run potato race, ball race and relays. It was fun watching them. We made their parents also have a book balancing race and Tug of war.", "To help with conducting this event we sent out Google CAS forms to different schools for students for volunteering. Many children volunteered from schools like JNIS, OIS etc. They all were given CAS certificates in the end. The highlight of the event was the surprise visit by Santa Claus which was organised by us. Santa met the students, clicked photographs with them & distributed chocolates & sweets.", "All the winning students were given Certificates, Medals & chocolates which was organised by Khel Katha team."]
    },
    {
        "mediaPath": "/assets/events/sports-day/sports-1/",
        "images" : 18,
        "videos" : 5,
        "header": ["Date : 20th Dec, 2022", "Venue : Bal Vikas Manoranjan Maidan"],
        "data" : ["This year Khel Katha got the opportunity to host the sports day along with Each One Teach One foundation. 22 Municipal Schools from Maharashtra took part in it.", "We started the sports day with a prayer followed by warmup through Zumba. To our delight the Zumba section was conducted by an old student of EOTO. She has now become a physical trainer in a school. Feels good to see our students going places. The founder of EOTO Ms. Jyoti Tanna gave a motivational speech.", "We had around 150 students taking part in different athletic competitions like 100mt, 200mt races, relays and long jumps. Balwadi children along with their parents also participated in these races. These children were between the age group of 3-5 years old. They were very enthusiastic to run potato race, ball race and relays. It was fun watching them. We made their parents also have a Vegetable Relay race and Tug of War. The parents who came first 3 were given Cooking Pans as prizes.", "To help with conducting this event we sent out Google CAS forms to different schools for students for volunteering. Many children volunteered from schools like JNIS, OIS etc. They all were given CAS certificates in the end. The highlight of the event was the surprise visit by Santa Claus which was organised by us. Santa met the students, clicked photographs with them & distributed chocolates & sweets. Thanks to Rotary club for their generous donation of chocolates.", "All the winning students were given Certificates, Medals & chocolates which was organised by Khel Katha team. It was overall a great event and we are looking forward to organising more such events in the future."]
    }
]